<template>
  <div class="following">
    <PageHeader :title="$t('general.followersPage.title')" />

    <InfinityScroll
      v-if="followers && followers.length"
      :items="followers"
      :has-more="hasMoreFollowers"
      @rich-end="loadMore"
    >
      <template #default="{ item }">
        <FollowingUser :user="item.user" class="following__user" />
      </template>
    </InfinityScroll>
  </div>
</template>

<script>
import FollowingUser from "@/features/components/FollowingUser.vue";
import PageHeader from "@/layout/PageHeader.vue";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import { mapGetters, mapMutations } from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

const postsCallbackType = {
  setFollowers: "setFollowers",
  updateFollowers: "updateFollowers",
};

export default {
  name: "PageFollowing",
  components: { InfinityScroll, PageHeader, FollowingUser },
  mixins: [waitRequest, validationError],

  computed: {
    ...mapGetters({
      followers: "followers/followers",
      followersMeta: "followers/followersMeta",
    }),

    hasMoreFollowers() {
      return Boolean(this.followersMeta?.next_page_url);
    },

    page() {
      return this.followersMeta?.current_page || 1;
    },
  },

  data() {
    return {
      loadFollowersCallback: {
        [postsCallbackType.setFollowers]: this.setCurrentPosts,
        [postsCallbackType.updateFollowers]: this.updateCurrentPosts,
      },
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapMutations({
      setFollowers: "followers/setFollowers",
      updateFollowers: "followers/updateFollowers",
      setMeta: "followers/setMeta",
    }),

    initState() {
      this.initialLoadFollowers();
    },

    setCurrentPost(data) {

      this.setFollowers({ followers: [data] });
    },

    loadFollowers(successCallbackType = postsCallbackType.setFollowers) {
      if (this.requestInProgress) return;

      const successCallback = this.loadFollowersCallback[successCallbackType];
      const requestUrl = "/followers";

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    async initialLoadFollowers() {
      this.$showSpinner();

      this.loadFollowers(postsCallbackType.setFollowers);
    },

    loadMore() {
      if (this.hasMoreFollowers)
        return this.loadFollowers(postsCallbackType.updateFollowers);
    },

    setCurrentPosts(data) {
      const { current_page, data: followers } = data;

      this.setFollowers({ followers });
      this.setMeta({ meta: data });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      const followers = data.data;

      this.updateFollowers({ followers });
      this.setMeta({ meta: data });
    },

    loadPostError(errors) {
      console.log(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.following {
  &__user {
    width: 100%;
    padding: em(15) em(15);
  }
  &__list {
    flex: 1;
  }
}
</style>
